<template>
  <v-card>
    <v-card-text style="height: 100vh">
      <v-form class="pb-10" v-model="valid">
        <v-container>
          <v-card>
            <v-card-title>Client</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-combobox
                    label="Clients"
                    :items="clients"
                    v-model="client"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="address"
                    label="Mailing Address"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-divider class="my-4"></v-divider>
          <v-card class="mx-auto">
            <v-card-title>Contacts</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-list class="mb-4">
                    <v-list-item v-for="item in array" :key="item.key">
                      <v-row>
                        <v-col cols="12" lg="3">
                          <v-list-item-title> Jack Smith </v-list-item-title>
                        </v-col>
                        <v-col cols="12" lg="3">
                          <v-list-item-title>
                            <v-icon>mdi-email</v-icon>
                            joshua.dobrowolski@gmail.com
                          </v-list-item-title>
                        </v-col>
                        <v-col cols="12" lg="2">
                          <v-list-item-title>
                            <v-icon>mdi-phone</v-icon>
                            444-555-5566
                          </v-list-item-title>
                        </v-col>
                        <v-col cols="12" lg="2">
                          <v-chip color="blue lighten-4" class="ma-1">
                            Logistics
                          </v-chip>
                          <v-chip color="green lighten-1" class="ma-1">
                            Billing
                          </v-chip>
                        </v-col>
                        <v-col cols="12" lg="2">
                          <div class="float-right mr-2">
                            <v-btn color="blue" fab dark small class="mr-3">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn color="red" fab dark small>
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-btn
                      elevation="2"
                      fab
                      absolute
                      bottom
                      right
                      dark
                      color="green"
                      @click="updateContactOpen = true"
                    >
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
            <v-expand-transition>
              <div v-show="updateContactOpen" class="pb-5">
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="firstname"
                        :rules="nameRules"
                        label="First name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="lastname"
                        :rules="nameRules"
                        label="Last name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="E-mail"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="phone"
                        :rules="phoneRules"
                        label="Phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-combobox
                        v-model="chips"
                        :items="items"
                        label="Contact Type"
                        multiple
                      >
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click="select"
                            @click:close="remove(item)"
                          >
                            <strong>{{ item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn
                        class="float-right"
                        color="blue"
                        dark
                        @click="updateContactOpen = false"
                      >
                        Save Contact
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
          <v-divider class="my-4"></v-divider>
          <v-card>
            <v-card-title>Booking Info</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="eventName"
                    label="Event Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row>
                    <span class="text-h6 font-weight-light mb-6 ml-2">
                      Required Number of Guests
                    </span>
                  </v-row>
                  <v-row>
                    <v-slider
                      v-model="requiredGuests"
                      thumb-label="always"
                      :thumb-size="30"
                      class="mr-3"
                    ></v-slider>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row>
                    <span class="text-h6 font-weight-light mb-6 ml-2">
                      Expected Number of Guests
                    </span>
                  </v-row>
                  <v-row>
                    <v-slider
                      v-model="expectedGuests"
                      thumb-label="always"
                      :thumb-size="30"
                      class="mr-3"
                      :min="requiredGuests"
                      :max="67"
                    ></v-slider>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" xl="3">
                  <v-row justify="center">
                    <span class="text-h6 font-weight-light my-3">
                      Start Date
                    </span>
                  </v-row>
                  <v-row justify="center">
                    <v-date-picker
                      elevation="2"
                      v-model="eventStartDate"
                    ></v-date-picker>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6" xl="3">
                  <v-row justify="center">
                    <span class="text-h6 font-weight-light my-3">
                      Start Time
                    </span>
                  </v-row>
                  <v-row justify="center">
                    <v-time-picker elevation="2" format="ampm"></v-time-picker>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6" xl="3">
                  <v-row justify="center">
                    <span class="text-h6 font-weight-light my-3">
                      End Date
                    </span>
                  </v-row>
                  <v-row justify="center">
                    <v-date-picker
                      elevation="2"
                      v-model="eventEndDate"
                    ></v-date-picker>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6" xl="3">
                  <v-row justify="center">
                    <span class="text-h6 font-weight-light my-3">
                      End Time
                    </span>
                  </v-row>
                  <v-row justify="center">
                    <v-time-picker elevation="2" format="ampm"></v-time-picker>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-divider class="my-4"></v-divider>
          <v-row>
            <v-spacer />
            <v-btn class="green darken-4 mx-3" dark @click="submitBooking">
              Save
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    valid: false,
    //Form Fields
    clients: ["Hoffman", "Regnum Christi"],
    firstname: "",
    lastname: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 50 || "Name must be less than 50 characters",
    ],
    email: "",
    emailRules: [(v) => /.+@.+/.test(v) || "E-mail must be valid"],
    phone: "",
    phoneRules: [
      (v) =>
        /^\d{3}-\d{3}-\d{4}$/.test(v) ||
        "Phone number must be in format ###-###-####",
    ],
    chips: [],
    items: ["Logistics", "Billing"],
    expectedGuests: 30,
    requiredGuests: 20,
    array: [{ key: 1 }],
    updateContactOpen: false,
    eventStartTime: null,
    eventEndDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    eventEndTime: null,
  }),
  computed: {
    eventName: {
      get() {
        return this.$store.state.selectedBooking.eventDetails.name;
      },
      set(value) {
        this.$store.commit("updateSelectedBookingName", value);
      },
    },
    eventStartDate: {
      get() {
        return new Date(
          this.$store.state.selectedBooking.arrivalAndDeparture.startTimestamp.toDate()
        )
          .toISOString()
          .substr(0, 10);
        //
        // - new Date().getTimezoneOffset() * 60000
        //
      },
      set(value) {
        this.$store.commit("updateEventStartDate", value);
      },
    },
    address: {
      get() {
        return this.$store.state.selectedClient.address;
      },
      set(value) {
        this.$store.commit("updateSelectedClientAddress", value);
      },
    },
  },
  methods: {
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },
    submitBooking() {
      this.$store.commit("submitBooking");
    },
  },
};
</script>
<style>
/* .v-card--reveal {
  bottom: auto;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
} */
</style>
